'use client'

import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import theme from "@/theme/theme";

export const StyledActionButton = styled(Button)<ButtonProps>(() => ({
  display: "flex",
  alignContent: "center",
  justifyItems: "center",
    color: theme.colors.mainGrey,
    "&.circle" :{
      width: "32px",
      height: "32px",
      padding: 0,
      "&.play-pause, &.volume": {
        "&.elevated": {
          backgroundColor: theme.colors.greyCold,
        },
        "&.ghost": {
          backgroundColor: "transparent",
          outline: "none",
          border: "none",
          "svg": {
              fill: theme.colors.mainGrey,
              stroke: theme.colors.mainGrey,
              width: '16px',
            },
          "&.red": {
            "svg": {
              fill: theme.colors.mainRed,
              stroke: theme.colors.mainRed,
            }
          }
        },
      }
    },
    '&:hover, &:focus, &:focus-visible': {
      "&.play-pause, &.volume": {
        "&.elevated": {
          backgroundColor: theme.colors.greyCold,
        },
        "&.ghost": {
          backgroundColor: "transparent",
          outline: "none",
          border: "none",
          "&.red": {
            "svg": {
              fill: theme.colors.mainRed,
              stroke: theme.colors.mainRed,
            }
          }
        },
      },
      'svg': {
        fill: theme.colors.mainGrey,
        stroke: theme.colors.mainGrey,
      }
    },
    'svg': {
      strokeWidth: 2,
      width: '16px',
    },
}));