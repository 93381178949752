"use client";
import {
  transitionTime,
  transitionZoomValue,
  transitionEaseOutBounce,
} from "../transitionsUtils";

export const transitionZoomFade = ($inView: boolean, $delay?: number) => {
  return {
    PointerEvent: "auto",
    opacity: $inView ? 1 : 0,
    transformOrigin: "center",
    width: "fit-content",
    transition: `all ${transitionTime}s ${transitionEaseOutBounce}`,
    transform: $inView ? `scale(${transitionZoomValue})` : "scale(0)",
    ...(typeof $delay === "number" && { transitionDelay: `${$delay}s` }),
  };
};
