import theme from "@/theme/theme";
import { Box, Theme, useMediaQuery } from "@mui/material";
import type { FC } from "react";

// Hook to check if the device is desktop or larger
export const useIsDesktop = () =>
  useMediaQuery<Theme>(theme.breakpoints.up("md"));

// Define the props for the component
interface MediaQueryComponentProps {
  aboveTheFold?: boolean;
  children: React.ReactNode;
}

// Component to show children on mobile devices only
export const Mobile: FC<MediaQueryComponentProps> = ({ children }) => (
  <Box sx={{ display: { xs: "inherit", md: "none" } }}>{children}</Box>
);

// Component to show children on desktop devices only
export const Desktop: FC<MediaQueryComponentProps> = ({ children }) => (
  <Box sx={{ display: { xs: "none", md: "inherit" } }}>{children}</Box>
);

// Component to show children on tablet devices only
export const Tablet: FC<MediaQueryComponentProps> = ({ children }) => (
  <Box sx={{ display: { xs: "none", sm: "inherit", md: "none" } }}>{children}</Box>
);

export const IEMediaQuery =
  "@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)";

export const EdgeMediaQuery = "@supports (-ms-ime-align:auto)";