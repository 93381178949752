const sizes = {
  basePadding: "8px",
  halfBasePadding: "4px",
  doublePadding: "16px",
  triplePadding: "24px",
  quadricPadding: "32px",
  padding12: "12px",
  containerPadding: "40px",
  containerPaddingHalf: "20px",

  baseMargin: "8px",
  doubleMargin: "16px",
  tripleMargin: "24px",
  quadricMargin: "32px",
  octoMargin: "64px",

  actionBtnsBottom: "20px",

  /* Gaps */
  gap: "16px",
  doubleGap: "32px",
  cardsGap: "24px",
  linksGap: "8px",

  desktop: {
    linksGap: "19px",
    cardsGap: "40px",
    actionBtnsBottom: "40px",
    containerPadding: "60px",
    containerPaddingHalf: "30px",
  },

  /* Font Sizes */
  fonts: {
    h1: "28px",
    h2: "28px",
    h3: "15px",
    h4: "17px",
    body: "14px",
    search: "17px",
    megaMenu: "15px",
    menu: "17px",
    button: "16px",
    content: "16px",
    subContent: "16px",
    small: "13px",
    gradient: "68px",
    tablet: {
      gradient: "68px",
      h1: "28px",
      h2: "28px",
      h3: "17px",
      h4: "17px",
      body: "17px",
      search: "17px",
      megaMenu: "17px",
      menu: "17px",
      button: "16px",
    },
    desktop: {
      h1: "40px",
      h2: "40px",
      h3: "24px",
      h4: "17px",
      body: "16px",
      search: "24px",
      megaMenu: "15px",
      menu: "14px",
      button: "20px",
      content: "20px",
      subContent: "18px",
    },
  },
  /* Border radiuses */
  radius: {
    small: "3px",
    medium: "7px",
    large: "12px",
    xlarge: "40px",
    circle: "50px",
  },
  /* Elements sizes */
  icon: {
    xXSmall: "10px",
    xSmall: "18px",
    small: "24px",
    medium: "32px",
    large: "40px",
    xLarge: "30px",
    desktop: {
      xLarge: "50px",
    },
  },
  /* Components sizes */
  leadComp: {
    cardWidth: "315px",
    cardHeight: "315px",
    cardLargeWidth: "670px",
    cardLargeHeight: "670px",
    titleWrapperPadding: "42px",
    gap: "40px",
    titles: {
      title: "40px",
      sub_title: "20px",
      black_title: "40px",
      red_title: "120px",
    },
    tablet: {
      cardWidth: "auto",
      cardHeight: "150px",
      cardLargeWidth: "315px",
      cardLargeHeight: "315px",
      titleWrapperPadding: "12px",
      gap: "18px",
      titles: {
        title: "17px",
        sub_title: "14px",
        black_title: "14px",
        red_title: "56px",
      },
    },
  },
};

export default sizes;
