"use client";
import theme from "@/theme/theme";
import { Box, Container, styled } from "@mui/material";

export const StyledSection = styled(Container)(() => ({
  display: "flex",
  flexDirection: "column",
  zIndex: 4,
  overflow: "hidden",
  [`${theme.mediaQueries.desktopM}`]: {
    overflow: "visible",
    flexDirection: "row",
    paddingLeft: 0,
    paddingRight: 0,
  },
  "&.no-bottom-padding": {
    paddingBottom: 0,
  },
  ".content": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: `${theme.sizes.triplePadding}`,
    color: theme.colors.mainGrey,
    background: "inherit",
    width: "100%",
    fontSize: theme.sizes.fonts.content,
    [`${theme.mediaQueries.desktopM}`]: {
      padding: `${theme.sizes.triplePadding} ${theme.sizes.triplePadding} ${theme.sizes.triplePadding} 0`,
      fontSize: theme.sizes.fonts.desktop.content,
      width: "450px",
      flex: "0 0 auto",
    },
    " > *": {
      marginBottom: theme.sizes.baseMargin,
      ".MuiTypography-description": {
        fontSize: theme.sizes.fonts.content,
        [`${theme.mediaQueries.desktopM}`]: {
          fontSize: theme.sizes.fonts.desktop.content,
        },
      },
      ".disclaimer": {
        [`${theme.mediaQueries.desktopM}`]: {
          marginTop: theme.sizes.octoMargin,
        },
      },
      [`${theme.mediaQueries.desktopM}`]: {
        marginBottom: theme.sizes.baseMargin,
      },
    },
    button: {
      lineHeight: "unset",
    },
    ".diamonds-container": {
      display: "flex",
      flexDirection: "row",
      gap: "16px",
    },
    ".link-inline-container": {
      display: "inline-flex",
      alignItems: "baseline",
      flexFlow: "row wrap",
      gap: "16px",
      ".text": {
        fontSize: "18px",
        fontWeight: "bold",
      },
      a: {
        fontSize: theme.sizes.fonts.content,
      },
      [`${theme.mediaQueries.desktopM}`]: {
        display: "inline-flex",
        flexFlow: "row nowrap",
        ".text": {
          whiteSpace: "nowrap",
        },
      },
    },
  },
  ".img-wrapper": {
    display: "flex",
    alignItems: "end",
    flex: "0 0 auto",
    margin: "auto",
    "&.cut-image": {
      img: {
        width: "100%",
        margin: `-10% -20% 0 ${theme.sizes.octoMargin}`,
        [`${theme.mediaQueries.desktopM}`]: {
          width: "revert-layer",
          margin: `38px 0 -12% -28%`,
        },
      },
    },
    img: {
      flex: "0 0 auto",
      height: "auto",
    },
  },
}));

export const NumbersWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  gap: theme.sizes.doubleGap,
  ".number-item": {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginInlineStart: theme.sizes.doubleGap,
    "&::before": {
      left: `-${theme.sizes.doubleGap}`,
      content: '""',
      position: "absolute",
      borderLeft: "1px solid #f0000b",
      height: "70%",
    },
    h2: {
      fontSize: theme.sizes.fonts.desktop.h2,
      fontWeight: 500,
      "&:before": {
        content: "none",
      },
    },
    p: {
      fontSize: theme.sizes.fonts.body,
    },
    "&:first-of-type": {
      marginInlineStart: "unset",
      "&:before": {
        content: "none",
      },
    },
  },
});

export const ButtonWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: theme.sizes.baseMargin,
  [`${theme.mediaQueries.desktopM}`]: {
    width: "auto",
  },
  [`${theme.mediaQueries.desktopM}`]: {
    flexDirection: "row",
    gap: theme.sizes.doubleMargin,
  },
});
