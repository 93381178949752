"use client";
import { transitionTime, transitionEase } from "../transitionsUtils";

const arrowMoveAnimation = `
  @keyframes moveArrow {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-4px);
    }
    100% {
      transform: translateX(0);
    }
  }
`;

export const transitionNudgeEnd = ($inView: boolean) => {
  // Injecting keyframes into the document
  if (typeof document !== "undefined") {
    const styleSheet = document.styleSheets[0];
    styleSheet?.insertRule(arrowMoveAnimation, styleSheet.cssRules.length);
  }

  return {
    animation: $inView
      ? `moveArrow ${transitionTime}s ${transitionEase} infinite`
      : "none",
  };
};
