import { IntersectionOptions } from "react-intersection-observer";

export const transitionTime = 1.5;
export const delayTime = 0.5;
export const transitionEase = "ease";
export const transitionEaseIn = "ease-in";
export const transitionEaseOut = "ease-out";
export const transitionEaseInOut = "ease-in-out";
export const transitionEaseOutBounce = `cubic-bezier(.42,1.2,.47,1) ${transitionTime}s`;

export const transitionStartValue = "-100%";
export const transitionUpValue = "50px";
export const transitionZoomValue = "1";

export const useInViewConfig: IntersectionOptions = {
  triggerOnce: true,
  threshold: 0.4,
  rootMargin: "300px 0px 0px 0px",
};

export const styleObjectToCss = (styles: Record<string, any>): string => {
  return Object.entries(styles)
    .map(([key, value]) => {
      const cssKey = key.replace(/([A-Z])/g, "-$1").toLowerCase(); // converts camelCase to kebab-case
      return `${cssKey}: ${value};`;
    })
    .join(" ");
};
