export function capitalizeFirstLetter(str: string): string {
    if (str.length === 0) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function toPascalCase(str: string): string {
    return str
        .split(/[ _-]/)
        .map(word => {
            return capitalizeFirstLetter(word);
        })
        .join('');
}

// Format number with commas using toLocaleString
export const formatNumberWithCommas = (val: string | number, type: string): string => {
    if (!val) return "";
    
    // For number type inputs, format with commas
    if (type === "number") {
      const numValue = parseFloat(String(val).replace(/,/g, ""));
      if (!isNaN(numValue)) {
        return numValue.toLocaleString('en-US');
      }
    }
    
    return String(val);
  };
  
  // Remove commas for validation and submission
export const unformatNumber = (val: string, type: string): string => {
    return type === "number" ? val.replace(/,/g, "") : val;
  };