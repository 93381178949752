"use client"
import { ThemeOptions, createTheme } from "@mui/material/styles"
import colors from "./colors"
import { mediaQueries, mediaQueriesValues } from "./mediaQueries"
import sizes from "./sizes"
import "./theme"
import "./types"

export const isLocalHost = process.env.NEXT_PUBLIC_ORIGIN?.includes("localhost")

export const imageUrl =
  process.env.NEXT_PUBLIC_LOCAL_IMG_PATH === "/"
    ? process.env.NEXT_PUBLIC_LOCAL_IMG_PATH
    : "/sites/default/files/media/"

const muiThemeOptions: ThemeOptions = {
  direction: "rtl",
  breakpoints: {
    values: mediaQueriesValues,
  },
  palette: {
    mode: "light",
    primary: {
      main: colors.mainRed,
    },
    secondary: {
      main: colors.mainGrey,
    },
  },
  typography: {
    fontFamily: "var(--fonts-next)",
    h1: {
      position: "relative",
      fontSize: sizes.fonts.h1,
      fontWeight: 700,
      [`${mediaQueries.desktopM}`]: {
        fontSize: sizes.fonts.desktop.h1,
      },
    },
    h2: {
      position: "relative",
      fontSize: sizes.fonts.h2,
      fontWeight: 700,
      [`${mediaQueries.desktopM}`]: {
        fontSize: sizes.fonts.desktop.h2,
      },
      "&:before": {
        position: "absolute",
        content: '""',
        height: "26px",
        borderLeft: `2px solid ${colors.mainRed}`,
        marginLeft: `-${sizes.basePadding}`,
        [`${mediaQueries.desktopM}`]: {
          height: "40px",
        },
      },
    },
    h3: {
      fontSize: sizes.fonts.h3,
      fontWeight: 700,
      [`${mediaQueries.desktopM}`]: {
        fontSize: sizes.fonts.desktop.h3,
      },
    },
    h4: {
      fontSize: sizes.fonts.h4,
      fontWeight: 700,
      [`${mediaQueries.desktopM}`]: {
        fontSize: sizes.fonts.desktop.h4,
      },
    },
    subtitle1: {
      // LinkMenu Items
      position: "relative",
      display: "flex",
      fontSize: sizes.fonts.menu,
      fontWeight: "normal",
      color: colors.mainGrey,
      borderBottom: "1px solid transparent",
      transition: "all 0.3s ease-in-out",
      "&:hover, &:active": {
        fontWeight: "bold",
        borderBottom: `1px solid ${colors.mainRed}`,
      },
    },
    disclaimer: {
      fontSize: sizes.fonts.desktop.body,
      "&::before": {
        content: '"*"',
        paddingInlineEnd: sizes.halfBasePadding,
        color: colors.mainRed,
        fontWeight: "900",
      },
      [`${mediaQueries.desktopM}`]: {
        fontSize: `${sizes.fonts.desktop.body}`,
      },
    },
    gradient: {
      // gradient title
      position: "relative",
      display: "flex",
      fontSize: sizes.fonts.gradient,
      fontWeight: "bold",
      background: colors.mainRedGradient,
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
      [`${mediaQueries.tablet}`]: {
        fontSize: sizes.fonts.tablet.gradient,
      },
    },
    description: {
      ul: {
        display: "flex",
        flexDirection: "column",
        padding: 0,
        gap: sizes.gap,
        li: {
          fontSize: sizes.fonts.desktop.body,
          "&::before": {
            content: '"*"',
            paddingInlineEnd: sizes.halfBasePadding,
            color: colors.mainRed,
            fontWeight: "900",
          },
          [`${mediaQueries.desktopM}`]: {
            fontSize: `${sizes.fonts.desktop.body}`,
          },
        },
      },
      [`${mediaQueries.desktopM}`]: {
        fontSize: sizes.fonts.desktop.body,
      },
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        a {
          color: ${colors.mainGrey};
          width: fit-content;
        }
      `,
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          position: "relative",
          padding: "6px 0",
          "&::before": {
            content: '"*"',
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            marginInlineEnd: "6px",
            marginTop: "4px",
            lineHeight: "unset",
            color: colors.mainRed,
          },
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            border: `1px solid ${colors.mainBlue}`,
            color: colors.mainBlue,
            background: "transparent",
            svg: {
              fill: colors.mainBlue,
              mouseEvent: "none",
            },
            "&:hover": {
              color: colors.mainBlue,
              border: `1px solid ${colors.mainBlue}`,
              background: colors.secondaryWhiteBlanca,
            },
            "&:focus-visible": {
              outline: `1px solid ${colors.mainGrey}`,
              border: `1px solid ${colors.mainBlue}`,
            },
          },
        },
        {
          props: { variant: "inverted" },
          style: {
            border: `1px solid ${colors.white}`,
            color: colors.white,
            background: "transparent",
            svg: {
              fill: colors.white,
            },
            "&:hover": {
              color: colors.mainBlue,
              border: `1px solid ${colors.mainBlue}`,
              background: colors.secondaryWhiteBlanca,
              svg: {
                fill: colors.mainBlue,
              },
            },
            "&:focus-visible": {
              outline: `1px solid ${colors.mainGrey}`,
              border: `1px solid ${colors.mainBlue}`,
            },
          },
        },
        {
          props: { variant: "chip" },
          style: {
            color: colors.mainBlue,
            background: "transparent",
            border: `1px solid ${colors.greyCold}`,
            boxShadow: colors.dropShadow.light,
            "&:hover": {
              color: colors.mainBlue,
              border: `1px solid ${colors.mainGrey}`,
              background: colors.secondaryWhiteBlanca,
            },
            "&:focus-visible": {
              outline: `1px solid ${colors.mainGrey}`,
              border: `1px solid ${colors.mainBlue}`,
            },
          },
        },
        {
          props: { variant: "elevated" },
          style: {
            border: `1px solid transparent`,
            color: colors.mainRed,
            background: colors.white,
            boxShadow: colors.dropShadow.main,
            stroke: colors.mainRed,
            "&.circle": {
              stroke: colors.mainGrey,
            },
            "&:hover": {
              border: `1px solid ${colors.greyAlpha05}`,
              color: colors.mainGrey,
              background: colors.secondaryWhiteBlanca,
            },
            "&:focus": {
              border: `1px solid transparent`,
              outline: "none",
            },
            "&:focus-visible": {
              border: `1px solid ${colors.mainRed}`,
              color: colors.mainRed,
              outline: "none",
            },
            "&:active": {
              border: `1px solid ${colors.mainRed}`,
              color: colors.white,
              background: colors.mainRed,
              svg: {
                stroke: colors.white,
                fill: colors.white,
              },
              outline: "none",
            },
          },
        },
        {
          props: { variant: "ghost" },
          style: {
            border: `1px solid transparent`,
            color: colors.mainBlue,
            stroke: colors.mainRed,
            background: "transparent",
            "&:hover": {
              border: `1px solid transparent`,
              color: colors.mainGrey,
              background: "transparent",
              stroke: "transparent",
              outline: "none",
            },
            "&:focus, &:focus-visible": {
              border: `1px solid ${colors.secondaryWhiteBlue}`,
              outline: "none",
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "fit-content",
          padding: `${sizes.halfBasePadding} ${sizes.quadricPadding}`,
          margin: `${sizes.baseMargin} 0`,
          borderRadius: sizes.radius.circle,
          background: colors.mainRedGradient,
          border: `1px solid transparent`,
          color: colors.white,
          fontSize: sizes.fonts.button,
          [`${mediaQueries.desktopM}`]: {
            padding: `${sizes.basePadding} ${sizes.quadricPadding}`,
            fontSize: sizes.fonts.desktop.button,
          },
          "&.small": {
            padding: `${sizes.halfBasePadding} ${sizes.quadricPadding}`,
            fontSize: sizes.fonts.button,
          },
          "&:hover": {
            background: colors.mainRedDarkGradient,
          },
          "&:focus, &:focus-visible": {
            outline: `1px solid ${colors.mainGrey}`,
            border: `1px solid ${colors.white}`,
          },
          "&.Mui-disabled": {
            background: colors.greyCold,
            color: colors.greyLaurel,
            border: "none",
          },
          "&.circle": {
            minWidth: "unset",
            padding: "14px 16px",
            fontSize: "11px",
            justifyContent: "center",
            alignItems: "center",
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: colors.mainGrey,
          "&.Mui-checked": {
            color: colors.mainGrey, // Color when checked
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: 0,
          maxWidth: "100%",
          paddingTop: sizes.containerPadding,
          paddingBottom: sizes.containerPadding,
          [`${mediaQueries.desktopM}`]: {
            maxWidth: "1024px",
            paddingTop: sizes.desktop.containerPadding,
            paddingBottom: sizes.desktop.containerPadding,
            justifyContent: "space-between",
          },
          [`${mediaQueries.desktop}`]: {
            maxWidth: "1040px",
          },
          [`${mediaQueries.desktopXl}`]: {
            maxWidth: "1200px",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          boxShadow: colors.dropShadow.main,
          borderRadius: sizes.radius.circle,
          border: 0,
        },
        inputRoot: {
          display: "flex",
          padding: `${sizes.basePadding} ${sizes.quadricPadding} ${sizes.basePadding} ${sizes.quadricPadding}`,
          justifyContent: "flex-end",
          alignItems: "center",
          fontSize: sizes.fonts.search,
          backgroundColor: colors.white,
          borderRadius: sizes.radius.circle,
          border: `.5 solid ${colors.greyCold}`,
          outline: "none",
          gap: "14px",
          zIndex: 100,

          [`${mediaQueries.desktopM}`]: {
            fontSize: sizes.fonts.desktop.search,
          },
        },
        paper: {
          backgroundColor: colors.white,
          boxShadow: colors.dropShadow.main, // Customize the dropdown shadow
          borderRadius: sizes.radius.xlarge,
          margin: `${sizes.containerPaddingHalf} 0`,
          paddingInlineEnd: sizes.basePadding,
          scrollbarColor: `${colors.greySilver} ${colors.white}`,
          scrollbarWidth: "thin",
        },
        option: {
          a: {
            display: "flex",
            flexDirection: "row",
            textDecoration: "none",
            fontSize: sizes.fonts.search,
            padding: `${sizes.doublePadding} ${sizes.basePadding} ${sizes.basePadding} ${sizes.containerPaddingHalf}`,
            gap: "16px",
            [`${mediaQueries.desktopM}`]: {
              fontSize: sizes.fonts.desktop.search,
            },
            svg: {
              fontSize: "1em",
              stroke: colors.mainRed,
              flex: "0 0 auto",
            },
          },
          '&[aria-selected="true"]': {
            // Selected Option
          },
          "&:hover": {
            backgroundColor: colors.greyCold, // Hover Option
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            "&::placeholder": {
              // AutoComplete Placeholder Styles
              color: colors.mainGrey,
              opacity: 1,
              transition: "opacity 0.3s ease" /* Smooth fade */,
            },
          },
        },
      },
    },
  },
}

const theme = {
  ...createTheme(muiThemeOptions),
  colors,
  sizes,
  mediaQueries,
}

export default theme
