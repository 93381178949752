"use client";
import { transitionTime, transitionEase } from "../transitionsUtils";

export const transitionGrowEnd = ($inView: boolean, $delay?: number) => {
  return {
    PointerEvent: "auto",
    maxWidth: $inView ? "100%" : "10%",
    overflow: "hidden",
    transition: `max-width ${transitionTime}s ${transitionEase}`,
    ...(typeof $delay === "number" && { transitionDelay: `${$delay}s` }),
  };
};
