"use client";

import { transitionStart } from "@/lib/animations/transitions/transitionStart";
import {
  TwoPartTitleDefault,
  TwoPartTitleSimple,
  TwoPartOneColorTitleStyle,
} from "./styles";
import { TwoPartTitleProps } from "./types";
import { useInView } from "react-intersection-observer";
import { useInViewConfig } from "@/lib/animations/transitionsUtils";

export const TwoPartTitle: React.FC<TwoPartTitleProps> = ({ title, style }) => {
  const { ref, inView } = useInView(useInViewConfig);
  if (!style) style = transitionStart(inView, 0);

  return (
    <TwoPartTitleDefault variant="h2" ref={ref}>
      <div className="styled-container">
        <div style={style}>
          {Array.isArray(title) ? (
            title.map((titleItem, index) => (
              <span key={titleItem + index}>{titleItem}</span>
            ))
          ) : (
            <span style={style}>{title}</span>
          )}
        </div>
      </div>
    </TwoPartTitleDefault>
  );
};

export const TwoPartOneColorTitle: React.FC<TwoPartTitleProps> = ({
  title,
  style,
}) => {
  const { ref, inView } = useInView(useInViewConfig);
  if (!style) style = transitionStart(inView, 0);

  return (
    <TwoPartOneColorTitleStyle variant="h2" ref={ref}>
      <div className="styled-container">
        <div style={style}>
          {Array.isArray(title) ? (
            title.map((titleItem, index) => (
              <span key={titleItem + index}>{titleItem}</span>
            ))
          ) : (
            <span style={style}>{title}</span>
          )}
        </div>
      </div>
    </TwoPartOneColorTitleStyle>
  );
};

export const TwoPartSimpleTitle: React.FC<TwoPartTitleProps> = ({
  title,
  style,
}) => {
  const { ref, inView } = useInView(useInViewConfig);
  if (!style) style = transitionStart(inView, 0);

  return (
    <TwoPartTitleSimple variant="h2" ref={ref}>
      <div className="styled-container">
        <div className="titles-wrapper" style={style}>
          {Array.isArray(title) ? (
            title.map((titleItem, index) => (
              <span key={titleItem + index}>{titleItem}</span>
            ))
          ) : (
            <span style={style}>{title}</span>
          )}
        </div>
      </div>
    </TwoPartTitleSimple>
  );
};

export default TwoPartTitle;
