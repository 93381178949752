'use client'
import React from 'react';
import { ButtonProps } from '@mui/material';
import { StyledActionButton } from './styles';
import { VolumeOnStrokeIcon, VolumeOffStrokeIcon, CloseIcon, Icons } from '../../reusable/IconsComponents';

export interface StyledButtonProps extends ButtonProps {
  play: boolean,
  onClick?: (event: React.MouseEvent<HTMLButtonElement>)=> void
  ghostPlayPause?: "ghost" | "ghost red" | undefined,
  variant?: "ghost" | "elevated" | "inverted" | "chip" | undefined;
}

export const PlayPauseButton: React.FC<StyledButtonProps> = ({ onClick, className, variant, play, ghostPlayPause=false }) => {
  let iconSuffix = "Stroke";
  if(ghostPlayPause) iconSuffix = "Fill";
  const ImportedPlay = Icons[`Play${iconSuffix}Icon` as keyof typeof Icons] as React.FC;
  const ImportedPause = Icons[`Pause${iconSuffix}Icon` as keyof typeof Icons] as React.FC;
  
  return (
    <StyledActionButton className={`play-pause ${variant} ${className} ${ghostPlayPause}`} variant={variant} onClick={(e)=>onClick ? onClick(e) : ''}>
        { play ? <>{ImportedPause && <ImportedPause />}</> : <>{ImportedPlay && <ImportedPlay />}</>}
    </StyledActionButton>
  );
};

export const VolumeOnOffButton: React.FC<StyledButtonProps> = ({ onClick, className, variant, play }) => {  
  return (
    <StyledActionButton className={`volume ${variant} ${className}`} variant={variant} onClick={(e)=>onClick ? onClick(e) : ''}>
        { play ? <VolumeOffStrokeIcon /> : <VolumeOnStrokeIcon />}
    </StyledActionButton>
  );
};

export const CloseButton: React.FC<ButtonProps> = ({ onClick, className, variant }) => {  
  return (
    <StyledActionButton className={`close-button ${className}`} variant={variant} onClick={(e)=>onClick ? onClick(e) : ''}>
        <CloseIcon /> 
    </StyledActionButton>
  );
};
