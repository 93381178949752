
import { Box, BoxProps } from "@mui/material";
import { styled } from '@mui/material/styles';
import { CSSProperties } from "react";
import theme from "@/theme/theme";

export interface TooltipStyleTitleProps {
    text: string;
    style?: CSSProperties | undefined;
  }
  
const StyledTooltip = styled(Box)<BoxProps>(() => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: "center",
    padding: `${theme.sizes.basePadding} 24px`,
    color: theme.colors.mainGrey,
    width: 'fit-content',
    backgroundColor: theme.colors.white,
    boxShadow: theme.colors.dropShadow.mainSmall,
    ".red": {
        color: theme.colors.mainRed
    },
    '&:after': {
        content: '""',
        position: "absolute",
        top: "100%", /* Position the arrow at the bottom of the tooltip */
        left: "50%", /* Center horizontally */
        marginLeft: "-5px", /* Adjust positioning to center the arrow */
        borderWidth: "5px", /* Size of the arrow */
        borderStyle: "solid",
        borderColor: "#fff transparent transparent transparent", /* Arrow color */
      }
}));

const TooltipStyleTitle: React.FC<TooltipStyleTitleProps> = ({ text, style }) =>{
    return (
        <StyledTooltip style={style}>
            <span>{text}</span>
            <span className="red">*</span>
        </StyledTooltip>
    );
};

export default TooltipStyleTitle;