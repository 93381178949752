const colors = {
  /* Main Colors */
  mainRed: "#f0000b",
  mainBlue: "#03294c",
  mainGrey: "#525252",
  mainLightGrey: "#ededed",

  /* Secondary Colors */
  secondaryRed: "#e61e28",
  secondaryBeluga: "#edf2f2",
  secondaryWhiteBlue: "#eff6fe",
  secondaryWhiteBlanca: "#f4f9ff",
  secondaryWhiteIce: "#f9fbff",

  /* Whites */
  white: "#fff",
  whiteSmoke: "#f6f6f6",
  /* Blacks */
  black: "#000",
  blackBlue: "#03294c",
  blackCrimson1Alpha: "rgba(230, 30, 40, 0.1)",
  /* Greys */
  grey: "#242424",
  greyDark: "#424242",
  greyLight: "#ccc",
  greyLaurel: "#aaa",
  greySilver: "#d0d0d0",
  greyCold: "#e5e5e5",
  greyJupiter: "#e1e1e1",
  greyAlpha05: "rgba(255, 255, 255, 0.05)",
  greyAlpha06: "rgba(82, 82, 82, 0.6)",
  /* Reds */
  /* Blues */
  blueGrey: "#365c80",
  blueLightGrey: "#dcebf2",
  /* Generals */
  violet: "#9747ff",
  /* Semantic Colors */
  semanticPositive: "#2c817d",
  semanticSuccess: "#008810",
  semanticError: "#df1a1e",
  semanticNegative: "#c01039",
  semanticWaiting: "#d18331",
  semanticInformation: "#206cb2",
  /* Gradients */
  mainRedGradient:
    "linear-gradient(90deg, #c01039, #dc1223 71.88%, #de1e1e 88.88%, #f0000b)",
  mainRedDarkGradient:
    "linear-gradient(90deg, #900011, #bf0615 71.88%, #cb0a0a 88.88%, #d02010)",
  mainBlueGradient: "linear-gradient(180deg, #303459, #151730)",
  darkOverlayGradient:
    "linear-gradient(114.51deg, rgba(14, 14, 14, 0) 26.32%, #0e0e0e 76%)",
  lightOverlayGradient:
    "linear-gradient(114.51deg, rgba(255, 255, 255, 0) 26.32%, white 76%)",
  lightOverlayGradientMobile:
    "linear-gradient(180deg, rgba(255, 255, 255, 0) 27.72%, rgba(255, 255, 255, 0.6) 42.86%, white 63.15%)",
  popupOverlayGradient:
    "linear-gradient(180.41deg, rgba(255, 255, 255, 0.96), rgba(255, 255, 255, 0.96) 60%, rgba(112, 29, 29, 0.4))",
  WhiteOverlayGradient:
    "linear-gradient(180.41deg, rgba(255, 255, 255, 0.48), rgb(255, 255, 255) 50%, rgb(255, 255, 255))",
  /* Others */
  dropShadow: {
    main: "0px 0px 20px rgba(0, 0, 0, 0.1)",
    mainSmall: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    light: "0px 0px 9px rgba(0, 0, 0, 0.06)",
  },
};

export default colors;
