"use client";

import { handleGTMEvent } from "@/common/GTMEventSender";
import { Mobile } from "@/common/MediaQueries";
import { transitionGrowEnd } from "@/lib/animations/transitions/transitionGrowEnd";
import {
  transitionCostumeStart,
  transitionStart,
} from "@/lib/animations/transitions/transitionStart";
import { transitionUpFade } from "@/lib/animations/transitions/transitionUpFade";
import { transitionZoomFade } from "@/lib/animations/transitions/transitionZoomFade";
import { delayTime, useInViewConfig } from "@/lib/animations/transitionsUtils";
import { Box, Button, List, ListItem, Typography } from "@mui/material";
import Image, { ImageProps } from "next/image";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import ArrowLink, { StyledLinkProps } from "../reusable/ArrowLink/ArrowLink";
import CheckBullet from "../reusable/CheckBullet/CheckBullet";
import Tooltip from "../reusable/TooltipStyleTitle";
import TwoPartTitle, {
  TwoPartOneColorTitle,
  TwoPartSimpleTitle,
} from "../reusable/TwoPartsTitles/TwoPartsTitle";
import { ButtonWrapper, NumbersWrapper, StyledSection } from "./styles";
import {
  CustomImageProps,
  MainCompDataType,
  MainContentComponentProps,
  NumberItemType,
} from "./types";
import DiamondWithLink, { DiamondItem } from "../reusable/DiamondWithLink";

function isDiamondItemArray(arr: any[]): arr is DiamondItem[] {
  return (
    arr.length > 0 &&
    typeof arr[0] === "object" &&
    "someDiamondProperty" in arr[0]
  );
}

function isNumberItemTypeArray(arr: any[]): arr is NumberItemType[] {
  return (
    arr.length > 0 &&
    typeof arr[0] === "object" &&
    "number" in arr[0] &&
    "text" in arr[0]
  );
}

/*  this component is in charge of all content in the project that can contain
    a combination titles, subtitles, buttons, images and running text.
    it's running through the data and apply the right element according to
    it's place in the data
*/
const MainContentComponent: React.FC<MainContentComponentProps> = ({
  data,
  children,
  GTMEventName,
  hideBtnOnDesktop,
}) => {
  const [imageStart, setImageStart] = useState<CustomImageProps | undefined>(); // image object
  const [imagePosition, setImagePosition] = useState("start"); // determine if image is before or after content
  const [cutImage, setCutImage] = useState(""); // determine if image is whole or cut out of component bounds
  const [items, setItems] = useState<MainCompDataType[] | undefined>(undefined); // list of elements in component
  const { ref, inView } = useInView(useInViewConfig);

  useEffect(() => {
    if (data) {
      const currData = Array.isArray(data)
        ? data // parse data if it doesn't have the wanted hierarchy
        : [data];
      const innerImage = currData.find((item) => {
        if (item?.image) return item?.image;
      });
      const image = innerImage?.image as CustomImageProps;
      if (image && typeof image === "object" && "src" in image && !imageStart) {
        const isStart = image.start;
        const { ...imageProps } = image as ImageProps;
        setImagePosition(isStart == true ? "start" : "end");
        setImageStart(imageProps);
      }
      const cutImage = currData.find((item) => {
        if (item?.cutImage) return item?.cutImage;
      });
      setCutImage(cutImage ? "cut-image" : "");
      setItems(currData);
    }
  }, [data]);

  return (
    <>
      <StyledSection ref={ref} className={children ? "no-bottom-padding" : ""}>
        {imagePosition === "start" && imageStart && (
          <div className={`img-wrapper ${cutImage}`}>
            <Image
              src={imageStart.src}
              height={imageStart.height}
              width={imageStart.width}
              alt={imageStart.alt}
            />
          </div>
        )}
        <div className="content">
          {items &&
            items.map((item, index) => (
              <React.Fragment key={`main-content-${index}`}>
                {Object.entries(item).map(([key, value]) => {
                  switch (key) {
                    case "preTitle": {
                      if (
                        value &&
                        typeof value === "object" &&
                        "type" in value &&
                        "content" in value
                      ) {
                        const { type = "text", content } = value;
                        const preTitleTransitionStyles = transitionZoomFade(
                          inView,
                          delayTime * (index + 1)
                        );
                        return (
                          <div key={`main-content-${key}-${index}`}>
                            {type === "text" && (
                              <Typography
                                color={"primary"}
                                sx={{ fontWeight: "bold" }}
                                style={{ ...preTitleTransitionStyles }}
                              >
                                {content as React.ReactNode}
                              </Typography>
                            )}
                            {type === "text-black" && (
                              <Typography
                                sx={{ fontWeight: "bold" }}
                                style={{ ...preTitleTransitionStyles }}
                              >
                                {content as React.ReactNode}
                              </Typography>
                            )}
                            {type === "tooltip" &&
                              typeof content === "string" && (
                                <Tooltip
                                  text={content}
                                  style={{ ...preTitleTransitionStyles }}
                                />
                              )}
                          </div>
                        );
                      }
                      break;
                    }
                    case "title": {
                      if (
                        typeof value === "string" ||
                        (Array.isArray(value) &&
                          typeof value[0] === "string" &&
                          !isNumberItemTypeArray(value) &&
                          !isDiamondItemArray(value))
                      ) {
                        const titleTransitionStyles = transitionStart(
                          inView,
                          delayTime * (index + 1)
                        );
                        return (
                          <TwoPartTitle
                            style={{ ...titleTransitionStyles }}
                            key={`main-content-${key}-${index}`}
                            title={value}
                          />
                        );
                      }
                      break;
                    }
                    case "titleOneColor": {
                      if (
                        typeof value === "string" ||
                        (Array.isArray(value) &&
                          typeof value[0] === "string" &&
                          !isNumberItemTypeArray(value) &&
                          !isDiamondItemArray(value))
                      ) {
                        const titleOneColorTransitionStyles = transitionStart(
                          inView,
                          delayTime * (index + 1)
                        );
                        return (
                          <TwoPartOneColorTitle
                            style={{ ...titleOneColorTransitionStyles }}
                            key={`main-content-${key}-${index}`}
                            title={value}
                          />
                        );
                      }
                      break;
                    }
                    case "titleSimple": {
                      if (
                        typeof value === "string" ||
                        (Array.isArray(value) &&
                          typeof value[0] === "string" &&
                          !isNumberItemTypeArray(value) &&
                          !isDiamondItemArray(value))
                      ) {
                        const titleSimpleTransitionStyles = transitionStart(
                          inView,
                          delayTime * (index + 1)
                        );
                        return (
                          <TwoPartSimpleTitle
                            style={{ ...titleSimpleTransitionStyles }}
                            key={`main-content-${key}-${index}`}
                            title={value}
                          />
                        );
                      }
                      break;
                    }
                    case "checkBullet": {
                      const checkBulletTransition = transitionCostumeStart(
                        inView,
                        "-120%",
                        delayTime * (index + 2)
                      );

                      const containerTransition = transitionGrowEnd(
                        inView,
                        delayTime * (index + 2)
                      );
                      return (
                        <CheckBullet
                          key={`main-content-${key}-${index}`}
                          style={checkBulletTransition}
                          styleContainer={containerTransition}
                          text={typeof value === "string" ? value : ""}
                        />
                      );
                      break;
                    }
                    // case "bullets":
                    case "arrowBullets":
                      return (
                        <List key={`main-content-${key}-${index}`}>
                          {Array.isArray(value) &&
                            value.map((bullet, i) => {
                              if (typeof bullet === "string")
                                return (
                                  <ListItem
                                    key={`main-content-${key}-${index}-${i}`}
                                  >
                                    {bullet}{" "}
                                  </ListItem>
                                );
                            })}
                        </List>
                      );
                      break;
                    case "numberItems":
                      return (
                        <List key={`main-content-${key}-${index}`}>
                          <NumbersWrapper className="numbers-container">
                            {Array.isArray(value) &&
                              value.map((item, i) => {
                                if (
                                  typeof item === "object" &&
                                  "number" in item &&
                                  ("text" in item || "description" in item)
                                )
                                  return (
                                    <div
                                      key={`main-content-${key}-${index}-${i}`}
                                      className="number-item"
                                    >
                                      <Typography variant="h2">
                                        <>{item.number}</>
                                      </Typography>
                                      <Typography>
                                        <>{item?.text}</>
                                      </Typography>
                                    </div>
                                  );
                              })}
                          </NumbersWrapper>
                        </List>
                      );
                      break;
                    case "diamondItems":
                      return (
                        <Box
                          key={`main-content-${key}-${index}`}
                          className="diamonds-container"
                        >
                          {Array.isArray(value) &&
                            value.map((item, i) => {
                              if (
                                item &&
                                typeof item === "object" &&
                                "text" in item &&
                                "link" in item
                              )
                                return (
                                  <DiamondWithLink
                                    key={`main-content-${key}-${index}-${i}`}
                                    link={item.link}
                                    text={item.text}
                                  />
                                );
                            })}
                        </Box>
                      );
                      break;
                    case "additionalButton":
                    case "button": {
                      const btnArr = Array.isArray(value) ? value : [value];
                      return (
                        <ButtonWrapper key={`main-content-${key}-${index}`}>
                          {btnArr &&
                            btnArr.map((item, i) => {
                              if (
                                item &&
                                typeof item === "object" &&
                                "label" in item &&
                                "href" in item
                              ) {
                                const itemId = `${GTMEventName ? GTMEventName : "general-button"}-${key}`;
                                const CurrBtn = (
                                  <Button
                                    key={`main-content-${key}-${index}-${i}`}
                                    variant={
                                      key === "additionalButton" ||
                                      (i == btnArr.length - 1 &&
                                        btnArr.length > 1)
                                        ? "outlined"
                                        : "contained"
                                    }
                                    onClick={() => {
                                      handleGTMEvent(
                                        GTMEventName
                                          ? GTMEventName
                                          : "main-button",
                                        itemId,
                                        {
                                          item_url: item?.href
                                            ? item.href
                                            : "null",
                                          item_category: GTMEventName,
                                          item_text: item?.label
                                            ? item?.label
                                            : "null",
                                        }
                                      );
                                    }}
                                    id={itemId}
                                    {...item}
                                  >
                                    {typeof item?.label === "string"
                                      ? item?.label
                                      : ""}
                                  </Button>
                                );
                                if (hideBtnOnDesktop) {
                                  return (
                                    <Mobile
                                      key={`main-content-${key}-${index}`}
                                    >
                                      {CurrBtn}
                                    </Mobile>
                                  );
                                } else {
                                  return (
                                    <div key={`main-content-${key}-${index}`}>
                                      {CurrBtn}
                                    </div>
                                  );
                                }
                              }
                            })}
                        </ButtonWrapper>
                      );
                      break;
                    }
                    case "linkInline":
                    case "link":
                      if (
                        value &&
                        typeof value === "object" &&
                        ("text" in value || "link" in value)
                      ) {
                        const { text = "" } = value;
                        const link = value.link as StyledLinkProps;
                        return (
                          <div
                            key={`main-content-${key}-${index}`}
                            className="link-inline-container"
                          >
                            {text && <Typography className="text">
                              {text as React.ReactNode}
                            </Typography>}
                            {link && <ArrowLink {...link} />}
                          </div>
                        );
                      }
                      break;
                    case "disclaimer":
                      {
                        if (value && typeof value === "string") {
                          const defaultTransitionStyles = transitionUpFade(
                            inView,
                            delayTime * (index + 1)
                          );
                          return (
                            <div
                              key={`main-content-${key}-${index}`}
                              style={defaultTransitionStyles}
                            >
                              <Typography
                                variant="body1"
                                className="disclaimer"
                              >
                                {value}
                              </Typography>
                            </div>
                          );
                        }
                      }
                      break;
                    case "text":
                    case "bullets":
                    case "description":
                    case "subtitle":
                    default: {
                      if (value && typeof value === "string") {
                        const defaultTransitionStyles = transitionUpFade(
                          inView,
                          delayTime * (index + 1)
                        );
                        return (
                          <Box
                            key={`main-content-${key}-${index}`}
                            style={defaultTransitionStyles}
                          >
                            <Typography
                              variant={key !== "subtitle" ? "body2" : "body1"}
                              sx={
                                key === "subtitle" ? { fontWeight: "bold" } : {}
                              }
                              dangerouslySetInnerHTML={{ __html: value }}
                            />
                          </Box>
                        );
                      }
                    }
                  }
                })}
              </React.Fragment>
            ))}
        </div>
        {imagePosition === "end" && imageStart && (
          <div className={`img-wrapper ${cutImage}`}>
            <Image
              src={imageStart.src}
              height={imageStart.height}
              width={imageStart.width}
              alt={imageStart.alt}
            />
          </div>
        )}
      </StyledSection>
      {children}
    </>
  );
};

export default MainContentComponent;
