"use client";
import { sendGTMEvent } from '@next/third-parties/google';
import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import Cookies from 'js-cookie';

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

interface GTMEventSenderProps {
  eventData: {
    event: string;
    pageUrl: string;
    pageTitle: string;
  };
}

export const handleGTMEvent = (event:string, id?:string, extraProps = {}) => {
  const bannerCookies = Cookies.get('banner_cookies');
  const bannerConsent = Cookies.get('consent');
  if(!bannerConsent || bannerConsent === 'true') {
    sendGTMEvent({
      event: event,
      item_id: id ? id : `${event}-general`,
      trigger: "on click",
      ...extraProps
    })
  }
}

export const pageview = (url: string) => {
    if (typeof window.dataLayer !== "undefined") {
      window.dataLayer.push({
        event: "pageview",
        page: url,
      });
    }
};

const GTMEventSender: React.FC<GTMEventSenderProps> = ({ eventData }) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (pathname) {
      pageview(pathname);
    }
  }, [pathname, searchParams]);

  useEffect(() => {
    handleGTMEvent(eventData.event, eventData.event, eventData);
  }, [eventData]);

  return null; // This component doesn't need to render anything
};

export default GTMEventSender;
