"use client";
import {
  transitionTime,
  transitionEase,
  transitionUpValue,
} from "../transitionsUtils";

export const transitionUpFade = ($inView: boolean, $delay?: number) => {
  return {
    PointerEvent: "auto",
    opacity: $inView ? 1 : 0,
    transition: `all ${transitionTime}s ${transitionEase}`,
    transform: $inView ? "translateY(0px)" : `translateY(${transitionUpValue})`,
    ...(typeof $delay === "number" && { transitionDelay: `${$delay}s` }),
  };
};
