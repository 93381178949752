"use client";
import {
  transitionTime,
  transitionStartValue,
  transitionEaseOutBounce,
  transitionEaseInOut,
} from "../transitionsUtils";

export const transitionStart = ($inView: boolean, $delay?: number) => {
  return {
    PointerEvent: "auto",
    flex: "0 0 auto",
    width: "100%",
    transition: `all ${transitionTime}s ${transitionEaseOutBounce}`,
    marginInlineStart: $inView ? "0px" : `${transitionStartValue}`,
    ...(typeof $delay === "number" && { transitionDelay: `${$delay}s` }),
  };
};

export const transitionCostumeStart = (
  $inView: boolean,
  startPoint?: string,
  $delay?: number
) => {
  return {
    PointerEvent: "auto",
    flex: "0 0 auto",
    transition: `all ${transitionTime}s ${transitionEaseOutBounce}`,
    overflow: "hidden",
    opacity: $inView ? 1 : 0,
    marginInlineStart: $inView
      ? "0px"
      : `${startPoint ? startPoint : transitionStartValue}`,
    ...(typeof $delay === "number" && { transitionDelay: `${$delay}s` }),
  };
};

export const transitionOutLeftStart = (
  $inView: boolean,
  startPoint?: string,
  $delay?: number
) => {
  return {
    PointerEvent: "auto",
    flex: "0 0 auto",
    transition: `all ${transitionTime * 5}s ${transitionEaseInOut}`,
    overflow: "hidden",
    opacity: 1,
    left: $inView ? "0px" : `${startPoint ? startPoint : transitionStartValue}`,
    ...(typeof $delay === "number" && { transitionDelay: `${$delay}s` }),
  };
};
export const transitionOutRightStart = (
  $inView: boolean,
  startPoint?: string,
  $delay?: number
) => {
  return {
    PointerEvent: "auto",
    flex: "0 0 auto",
    transition: `all ${transitionTime * 5}s ${transitionEaseInOut}`,
    overflow: "hidden",
    opacity: 1,
    right: $inView
      ? "0px"
      : `${startPoint ? startPoint : transitionStartValue}`,
    ...(typeof $delay === "number" && { transitionDelay: `${$delay}s` }),
  };
};
