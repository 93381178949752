const mediaQueriesValues = {
    xs: 0,   // Extra small devices (phones)
    sm: 768, // Small devices (tablets)
    md: 1024, // Medium devices (desktops)
    lg: 1280, // Large devices (large desktops)
    xl: 1600, // Extra large devices (large screens)
}

const mediaQueries ={
    mobile: `@media (max-width: ${mediaQueriesValues.xs}px)`, // Extra small devices (phones)
    tablet: `@media (min-width: ${mediaQueriesValues.sm}px)`, // Small devices (tablets)
    desktopM: `@media (min-width: ${mediaQueriesValues.md}px)`, // Medium devices (small laptops)
    desktop: `@media (min-width: ${mediaQueriesValues.lg}px)`, // Large devices (desktops)
    desktopXl: `@media (min-width: ${mediaQueriesValues.xl}px)`, // Extra large devices (large desktops)
  };

  export {mediaQueries, mediaQueriesValues};
  