'use client'

import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import theme from "@/theme/theme";

export const CheckBulletWrapper = styled(Box)(() => ({
    display: "flex",
    flexDirection: "row",
    width: "fit-content",
    borderRadius: theme.sizes.radius.xlarge,
    fontSize: theme.sizes.fonts.h4,
    fontWeight: "bold",
    color: theme.colors.white,
    backgroundColor: theme.colors.greyAlpha06,
    boxShadow: theme.colors.dropShadow.main,
    alignItems: "center",
    padding: `${theme.sizes.basePadding} ${theme.sizes.doublePadding}`,
    gap: theme.sizes.basePadding,
    mixBlendMode: "normal",
    marginBottom: "0",
    "svg": {
        marginBottom: "0",
        flex: "0 0 auto"
    }
}));