import { styled } from '@mui/material/styles';
import theme from "@/theme/theme";
import { Box, Collapse } from '@mui/material';

export const CollapseWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
})

export const CollapseTitle = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: "space-between",
    width: '100%',   
    alignItems: "center",
    fontWeight: '700',
    padding: theme.sizes.doublePadding,
    borderBottom: `1px solid ${theme.colors.greyCold}`,
    "h6": {
        fontSize: theme.sizes.fonts.megaMenu,
        "&.open": {
            "p":{
                fontWeight: '700',
            }
        }
    },
    "svg": {
        fill: theme.colors.mainRed,
        height: theme.sizes.icon.xSmall,
        transition: "all 0.2s ease-in-out",
        transform: "rotate(90deg)",
        "&.open": {
            transform: "rotate(-90deg)",
        }
    }
})

export const CollapseItem = styled(Collapse)({
    ".MuiCollapse-wrapperInner": {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        transition: "all 0.3s ease-in-out",
        gap: theme.sizes.gap,
        padding: `${theme.sizes.doublePadding} 0`,
        "a": {
            width: '100%',
            textDecoration: "none",
            padding: `${theme.sizes.padding12} ${theme.sizes.doublePadding}`,
        }
    }
})