import { Button, Typography } from "@mui/material";
import React from "react";
import MainContentComponent from "../MainContentComponent/MainContentComponent";
import { ContentWrapper, AppButtonsWrapper } from "./styles";
import Image from "next/image";
import { delayTime, useInViewConfig } from "@/lib/animations/transitionsUtils";
import { useInView } from "react-intersection-observer";
import { transitionUpFade } from "@/lib/animations/transitions/transitionUpFade";
import { AppsButtonsProps } from "./types";
import { handleGTMEvent } from "@/common/GTMEventSender";

const AppsButtonsComponent: React.FC<AppsButtonsProps> = ({
  data,
  logo,
  appButtons,
  disableAnimation,
  GTMEventName,
}) => {
  const { ref, inView } = useInView(useInViewConfig);
  const buttonsTransitionStyles = transitionUpFade(inView, delayTime * 4);
  return (
    <ContentWrapper ref={ref} className="apps-buttons">
      {logo && <Image className="apps-logo" {...logo} />}
      <MainContentComponent
        data={Array.isArray(data) ? data : [data]}
        GTMEventName={GTMEventName}
      />
      {appButtons && (
        <AppButtonsWrapper
          style={disableAnimation ? {} : buttonsTransitionStyles}
        >
          {Object.values(appButtons).map((item, index) => {
            const btnName = index === 1 ? "apple" : "google";
            if (typeof item === "string")
              return <Typography key={`app-text-${index}`}>{item}</Typography>;
            return (
              <Button
                key={`app-button-${index}`}
                id={`${GTMEventName}-app-button-${item.link}`}
                variant="ghost"
                href={item.link}
                onClick={() =>
                  handleGTMEvent(
                    GTMEventName ? GTMEventName : "cards-outer-link-button",
                    `${GTMEventName}-app-button-${item.link}`,
                    {
                      item_url: item?.link ? item?.link : "",
                      item_text: btnName,
                    }
                  )
                }
              >
                <Image {...item.image} />
              </Button>
            );
          })}
        </AppButtonsWrapper>
      )}
    </ContentWrapper>
  );
};

export default AppsButtonsComponent;
