"use client";

import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ArrowLink, { StyledLinkProps } from "./ArrowLink/ArrowLink";
import theme from "@/theme/theme";

export interface DiamondItem {
  link: Omit<StyledLinkProps, "text"> & { text: string };
  text: string;
}

const StyledSDiv = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "0 16px",
}));

const DiamondContainer = styled(Box)(() => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "80px", // width and height must be equal for the diamond shape to be
  height: "80px", // width and height must be equal for the diamond shape to be
  margin: "40px auto", // margin must be half of width and height for the diamond to be centered
  transform: "rotate(45deg)",
  borderRadius: theme.sizes.radius.large,
  backgroundColor: theme.colors.white,
  boxShadow: `${theme.colors.dropShadow.main}`,
}));

const ContentContainer = styled(Box)(() => ({
  transform: "rotate(-45deg)",
  textAlign: "center",
  color: theme.colors.mainGrey,
  fontSize: theme.sizes.fonts.small,
  lineHeight: "16px",
}));

const DiamondWithLink: React.FC<DiamondItem> = ({ link, text }) => {
  return (
    <StyledSDiv>
      <DiamondContainer>
        <ContentContainer>{text}</ContentContainer>
      </DiamondContainer>
      {link && <ArrowLink {...link} />}
    </StyledSDiv>
  );
};

export default DiamondWithLink;
