import React, { CSSProperties } from "react";
import { CheckBulletWrapper } from "./styles";
import { BenefitIcon } from "../../reusable/IconsComponents";
import { transitionCostumeStart } from "@/lib/animations/transitions/transitionStart";
import { useInView } from "react-intersection-observer";
import { useInViewConfig } from "@/lib/animations/transitionsUtils";
import { Typography } from "@mui/material";
import { transitionGrowEnd } from "@/lib/animations/transitions/transitionGrowEnd";

interface CheckBulletProps {
  text: string;
  style?: CSSProperties | undefined;
  styleContainer?: CSSProperties | undefined;
}

const CheckBullet: React.FC<CheckBulletProps> = ({
  text,
  style,
  styleContainer,
}) => {
  const { ref, inView } = useInView(useInViewConfig);

  if (!style) style = transitionCostumeStart(inView, "-500%", 1);
  if (!styleContainer) styleContainer = transitionGrowEnd(inView);

  return (
    <CheckBulletWrapper
      ref={ref}
      style={styleContainer}
      className="check-bullet"
    >
      <BenefitIcon />
      <Typography style={style}>{text}</Typography>
    </CheckBulletWrapper>
  );
};

export default CheckBullet;
