'use client';

import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles';
import theme from "@/theme/theme";

export const TwoPartTitleDefault = styled(Typography)(() => ({
  display: "flex",
  flexDirection: "row",
  ".styled-container": {
    width: "100%",
    overflow: "hidden",
    'span:first-of-type': {
      color: theme.colors.mainRed,
      marginInlineEnd: theme.sizes.baseMargin
    },
    'span:last-of-type': {
      color: theme.colors.mainGrey,
    },
  }
}));

export const TwoPartOneColorTitleStyle = styled(Typography)(() => ({
    display: "flex",
    flexDirection: "column",
    color: "inherit",
    ".styled-container": {
      width: "100%",
      overflow: "hidden",
      'span:first-of-type': {
        color: theme.colors.mainGrey,
      },
      'span:last-of-type': {
        color: theme.colors.mainGrey,
      },
    }
}));

export const TwoPartTitleSimple = styled(Typography)(() => ({
    display: "flex",
    flexDirection: "column",
    color: "inherit",
    "&:before": {
      content: "none"
    },
    ".styled-container": {
      width: "100%",
      overflow: "hidden",
      ".titles-wrapper": {
        display: "flex",
        flexDirection: "column",
        'span:first-of-type': {
          fontWeight: 'normal'
        },
        'span:last-of-type': {
          fontWeight: 'bold'
        },
      }
    }
  }));