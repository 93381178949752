"use client";

import React, { CSSProperties } from "react";
import { ButtonProps, LinkProps } from "@mui/material";
import ArrowLeft from "@/public/icons/General/chevron-left.svg";
import ArrowDown from "@/public/icons/General/arrow-down.svg";
import {
  StyledLink,
  StyledLinkDown,
  StyledButton,
  StyledButtonDown,
} from "./styles";
import { Url } from "next/dist/shared/lib/router/router";
import { Desktop } from "@/common/MediaQueries";
import { useInView } from "react-intersection-observer";
import { useInViewConfig } from "@/lib/animations/transitionsUtils";
import { transitionNudgeEnd } from "@/lib/animations/transitions/transitionNudgeEnd";

export interface StyledLinkProps extends LinkProps {
  link?: {
    href: Url | undefined;
    text: string;
  };
  href: string;
  text: string;
  noTextMobile?: boolean;
  style?: CSSProperties | undefined;
}

export interface StyledButtonProps extends ButtonProps {
  text?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ArrowLink: React.FC<StyledLinkProps> = ({
  href,
  onClick,
  text,
  className,
  noTextMobile = false,
  style,
}) => {
  const { ref, inView } = useInView(useInViewConfig);
  if (!style) style = transitionNudgeEnd(inView);

  return (
    <StyledLink className={className} onClick={onClick} ref={ref} href={href}>
      {noTextMobile ? <Desktop>{text}</Desktop> : text}
      <ArrowLeft style={style} />
    </StyledLink>
  );
};

export const ArrowLinkNoAnimation: React.FC<StyledLinkProps> = ({
  href,
  text,
  className,
  noTextMobile = false,
}) => {
  return (
    <StyledLink className={className} href={href}>
      {noTextMobile ? <Desktop>{text}</Desktop> : text}
      <ArrowLeft />
    </StyledLink>
  );
};

export const ArrowDownLink: React.FC<StyledLinkProps> = ({
  href,
  text,
  className,
}) => {
  return (
    <StyledLinkDown className={className} href={href}>
      {text}
      <ArrowDown />
    </StyledLinkDown>
  );
};
export const ArrowDownLinkButton: React.FC<StyledButtonProps> = ({
  text,
  onClick,
  className,
  variant,
}) => {
  return (
    <StyledButtonDown
      className={className}
      variant={variant}
      onClick={(e) => (onClick ? onClick(e) : "")}
    >
      {text}
      <ArrowDown />
    </StyledButtonDown>
  );
};

export const ArrowLinkButton: React.FC<StyledButtonProps> = ({
  text,
  onClick,
  className,
  variant,
}) => {
  return (
    <StyledButton
      className={className}
      variant={variant}
      onClick={(e) => (onClick ? onClick(e) : "")}
    >
      {text}
      <ArrowLeft />
    </StyledButton>
  );
};

export default ArrowLink;
