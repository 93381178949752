"use client";

import { Button, ButtonProps, LinkProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { SxProps, Theme } from "@mui/system";
import theme from "@/theme/theme";

export const arrowLinkSharedStyle: SxProps<Theme> = {
  position: "relative",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  textDecoration: "none",
  width: "fit-content",
  flex: "0 0 auto",
  "&:hover": {
    textDecoration: "underline",
  },
};

export const StyledLink = styled("a")<LinkProps>(() => ({
  ...arrowLinkSharedStyle,
  color: theme.colors.mainGrey,
  svg: {
    fontSize: "0.7em",
    fill: theme.colors.mainRed,
    marginInlineStart: `${theme.sizes.baseMargin}`,
    transform: theme.direction === "ltr" ? "rotate(180deg)" : "none", // Rotate the SVG if direction is 'rtl'
  },
}));

export const StyledLinkDown = styled("a")<LinkProps>(() => ({
  ...arrowLinkSharedStyle,
  color: theme.colors.mainGrey,
  whiteSpace: "nowrap",
  padding: 0,
  svg: {
    fontSize: "0.7em",
    fill: theme.colors.mainGrey,
    marginInlineStart: `${theme.sizes.baseMargin}`,
    transform: theme.direction === "ltr" ? "rotate(180deg)" : "none", // Rotate the SVG if direction is 'rtl'
  },
}));

export const StyledButton = styled(Button)<ButtonProps>(() => ({
  ...arrowLinkSharedStyle,
  color: theme.colors.mainGrey,
  fontSize: theme.sizes.fonts.body,
  padding: "none",
  border: "none",
  outline: "none",
  "&:hover, &:focus, &:focus-visible": {
    outline: "none",
    border: "none",
    backgroundColor: "transparent",
    textDecoration: "underline",
  },
  svg: {
    fontSize: "0.6em",
    fill: theme.colors.mainRed,
    marginInlineStart: `${theme.sizes.baseMargin}`,
    transform: theme.direction === "ltr" ? "rotate(180deg)" : "none", // Rotate the SVG if direction is 'rtl'
  },
}));

export const StyledButtonDown = styled(Button)<ButtonProps>(() => ({
  ...arrowLinkSharedStyle,
  color: theme.colors.mainGrey,
  fontSize: theme.sizes.fonts.body,
  padding: 0,
  border: "none",
  outline: "none",
  whiteSpace: "nowrap",
  "&:hover, &:focus, &:focus-visible": {
    outline: "none",
    border: "none",
    backgroundColor: "transparent",
    textDecoration: "underline",
  },
  svg: {
    fontSize: "0.7em",
    fill: theme.colors.mainGrey,
    stroke: "none",
    marginInlineStart: `${theme.sizes.baseMargin}`,
    transition: "all 0.3s ease-in-out",
    transform: "rotate(0deg)",
  },
  "&.open": {
    svg: {
      transform: "rotate(-180deg)",
    },
  },
}));
