'use client'
import { Box, BoxProps, Container, styled } from "@mui/material";
import theme from "@/theme/theme";

export const ContentWrapper = styled(Box)<BoxProps>(() => ({
  position: "relative",
  display:"flex",
  flexDirection: "column",
  ".MuiContainer-root": {
      paddingTop: 0,
      paddingBottom: 0,
      ".content": {
        paddingBottom: 0,
        "div:last-child": {
          marginBottom: 0
        }
      },
    },
    ".apps-logo": {
        width: "108px",
        height: "auto",
        [`${theme.mediaQueries.desktopM}`]: {
          width: "157px",
          marginInlineStart: theme.sizes.tripleMargin
      },
    },
  [`${theme.mediaQueries.desktopM}`]: {
    marginInlineEnd: theme.sizes.octoMargin,
    width: "45%",
    justifyContent: "space-between",
    ".MuiContainer-root": {
      paddingLeft: 0,
      ".content": {
        padding: 0,
        "div:last-child": {
          marginBottom: 0
        }
      },
    },
    ".apps-logo": {
      marginInlineStart: 0
    },
  }
}));

export const AppButtonsWrapper = styled(Container)<BoxProps>(({style}) => ({
  position: "relative",
  display:"flex",
  flexFlow: "row wrap",
  justifyContent: "center",
  textAlign: "center",
  paddingTop: 0,
  paddingRight: 0,
  paddingLeft: 0,
  "p": {
    width: "100%",
    marginTop: theme.sizes.tripleMargin,
    marginBottom: 0,
    marginInlineStart: theme.sizes.triplePadding,
    fontSize: theme.sizes.fonts.content,
    fontWeight: "bold",
    textAlign: "start",
    [`${theme.mediaQueries.desktopM}`]: {
      marginBottom: theme.sizes.baseMargin,
      marginInlineStart: 0,
        fontSize: theme.sizes.fonts.desktop.subContent,
    }
  },
  "a": {
    padding: 0,
    "&:last-child": {
      marginInlineStart: theme.sizes.baseMargin
    }
  },
  [`${theme.mediaQueries.desktopM}`]: {
    flexFlow: "row wrap",
    justifyContent: "flex-start",
    textAlign: "start",
    paddingTop: 0,
    paddingRight: theme.sizes.triplePadding,
    paddingLeft: theme.sizes.triplePadding
  },
  style
}));